import { Suspense } from 'react'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import FrontLayout from './Components/Layouts/FrontLayout'
import AuthLayout  from './Components/Layouts/AuthLayout'
import BackLayout  from './Components/Layouts/BackLayout'

import NotFoundPage from './Components/Errors/Pages/NotFoundPage'
import RouterError  from './Components/Errors/RouterError'

import Root from './Root'

import LayoutLoader from './Components/Loaders/LayoutLoader'

import MyMembershipLoader from './Components/Loaders/MyMembershipLoader'
import MyGroupLoader      from './Components/Loaders/MyGroupLoader'
import RecoLoader         from './Components/Loaders/RecoLoader'
import ThanksLoader       from './Components/Loaders/ThanksLoader'
import MtoMLoader         from './Components/Loaders/MtoMLoader'

import PatronageLoader from './Components/Loaders/PatronageRegistrationLoader'

import RootSpinner from './Components/Spinners/RootSpinner'

import AppVersion from './Components/Configs/AppVersion'

import ROUTES from './routes'

const routes =
[
	{
		path: '*',
		errorElement: RouterError,
		element: <NotFoundPage error="Cette page n'existe pas." center />
	},

	{
		path: '/',
		errorElement: RouterError,
		loader: LayoutLoader,
		element: <Root />,

		children:
		[
			{
				path: '',
				lazy: ROUTES.HomePage
			}
		]
	},

	{
		path: '/',
		errorElement: RouterError,
		loader: LayoutLoader,
		element: <FrontLayout />,

		children:
		[
			{
				path: 'parrainage/:token',
				loader: PatronageLoader,
				lazy: ROUTES.PatronageRegistrationPage
			}
		]
	},

	{
		path: '/',
		errorElement: RouterError,
		loader: LayoutLoader,
		element: <AuthLayout />,

		children:
		[
			{
				path: 'login',
				lazy: ROUTES.LoginPage
			},
			{
				path: 'mot-de-passe-oublie',
				lazy: ROUTES.RequestPasswordPage
			},
			{
				path: 'mot-de-passe-oublie/nouveau-mot-de-passe/:token',
				lazy: ROUTES.NewPasswordPage
			}
		]
	},

	{
		path: '/admin',
		errorElement: RouterError,
		loader: LayoutLoader,
		element: <BackLayout />,

		children:
		[
			{
				path: 'accueil',
				lazy: ROUTES.AdminHomePage
			},
			{
				path: 'liste-des-utilisateurs',
				lazy: ROUTES.UsersListPage
			},
			{
				path: 'liste-des-utilisateurs/nouvel-utilisateur',
				lazy: ROUTES.NewUserPage
			},
			{
				path: 'liste-des-utilisateurs/:user_id',
				lazy: ROUTES.UserPage
			},
			{
				path: 'membres-en-attente-de-validation',
				lazy: ROUTES.MembersToValidatePage
			}
		]
	},

	{
		path: '/gestion',
		errorElement: RouterError,
		loader: LayoutLoader,
		element: <BackLayout />,

		children:
		[
			{
				path: 'liste-des-groupes',
				lazy: ROUTES.GroupsListPage
			},
			{
				path: 'liste-des-groupes/nouveau-groupe',
				lazy: ROUTES.NewGroupPage
			},
			{
				path: 'liste-des-groupes/:group',
				lazy: ROUTES.GroupPage
			},
			{
				path: 'liste-des-adhesions',
				lazy: ROUTES.MembershipsListPage
			},
			{
				path: 'liste-des-adhesions/nouvelle-adhesion',
				lazy: ROUTES.NewMembershipPage
			},
			{
				path: 'liste-des-adhesions/:membership',
				lazy: ROUTES.MembershipPage
			},
			{
				path: 'mes-novas',
				lazy: ROUTES.MyNovasListPage
			},
			{
				path: 'recommandations',
				lazy: ROUTES.RecosListPage
			},
			{
				path: 'recommandations/faire-une-reco',
				lazy: ROUTES.NewRecoPage
			},
			{
				path: 'recommandations/:reco',
				loader: RecoLoader,
				lazy: ROUTES.RecoPage
			},
			{
				path: 'reunions',
				lazy: ROUTES.MeetingsListPage
			},
			{
				path: 'reunions/:id',
				lazy: ROUTES.MeetingPage
			},
			{
				path: 'tete-a-tete',
				lazy: ROUTES.MtoMListPage
			},
			{
				path: 'tete-a-tete/faire-un-tete-a-tete',
				lazy: ROUTES.NewMtoMPage
			},
			{
				path: 'tete-a-tete/:id',
				lazy: ROUTES.UpdateMtoMPage,
				loader: MtoMLoader
			},
			{
				path: 'remerciements',
				lazy: ROUTES.ThanksListPage
			},
			{
				path: 'remerciements/nouveau',
				lazy: ROUTES.NewThanksPage
			},
			{
				path: 'remerciements/:id',
				loader: ThanksLoader,
				lazy: ROUTES.ThanksPage
			},
			{
				path: 'mon-groupe',
				loader: MyGroupLoader,
				lazy: ROUTES.MyGroupPage
			},
			{
				path: 'mon-compte',
				lazy: ROUTES.MyAccountPage
			},
			{
				path: 'mon-adhesion',
				loader: MyMembershipLoader,
				lazy: ROUTES.MyMembershipPage
			}
		]
	}
];

const router = createBrowserRouter(routes);

export default function App ()
{
	return (
		<>
			<Suspense fallback={ <RootSpinner active /> }>
				<RouterProvider router={ router } />
			</Suspense>

			<AppVersion />
		</>
	);
}