import { defer } from 'react-router-dom'

import request from '../Functions/ajax/request'

import { someTime } from '../../../shared/utilities'

export default async function MyGroupLoader (params)
{
	return defer({ group: getMyGroup() });
}

async function getMyGroup (retry=true)
{
	try
	{
		var group = await request('/groups/get-mine').post();
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		group = await getMyGroup(false);
	}

	return group;
}