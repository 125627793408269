import { DateTime } from 'luxon'

import MembershipStore from '../Stores/MembershipStore'

export default function seMembershipStore ({ membership })
{
	MembershipStore.update(function (store)
	{
		store.membership = membership;

		store.date = DateTime.now().toISO();

		store.status = membership ? 'fetched' : 'no-membership';

		try
		{
			window.localStorage
			.setItem('membership', JSON.stringify(store.membership || { }));
		}
		catch (e)
		{
			console.error('Impossible d\'enregistrer l\'adhésion de l\'utilisateur');
		}

		window.localStorage.setItem('membership@date', store.date);
		window.localStorage.setItem('membership@status', store.status);
	});
}