import { useEffect } from 'react'

import { Outlet, useLoaderData } from 'react-router-dom'

import { PrimeReactProvider } from 'primereact/api'

import AdminUserMenu from '../Navigation/AdminUserMenu'
import AdminHeader   from '../Navigation/AdminHeader'

import request from '../Functions/ajax/request'
import bemit   from '../Functions/bemit'

import MembershipStore from '../../Stores/MembershipStore'
import MyGroupStore    from '../../Stores/MyGroupStore'
import RolesStore      from '../../Stores/Roles'

import setMembershipStore from '../../StoresFunctions/setMembershipStore'
import setMyGroupStore    from '../../StoresFunctions/setMyGroupStore'
import setRolesStore      from '../../StoresFunctions/setRolesStore'

import SuspendedLayout from '../../SuspendedLayout'

const prime_params = { ripple: true, unstyled: true };

export default function BackLayout ()
{
	const { page } = useLoaderData();

	const membership_status = MembershipStore.useState(s => s.status);
	const mygroup_status = MyGroupStore.useState(s => s.status);
	const roles_status = RolesStore.useState(s => s.status);

	useEffect(() =>
	{
		if (roles_status !== 'pending') return;

		const controller = new AbortController();

		(async function fetchRoles ()
		{
			try
			{
				var { roles, user_id } = await request('/auth/get-roles')
				.config({ controller })
				.post();
			}
			catch (update)
			{
				return;
			}

			setRolesStore({ roles, user_id });
		})();

		return () => controller?.abort?.();
	},
	[roles_status]);

	useEffect(() =>
	{
		if (mygroup_status === 'fetched') return;

		const controller = new AbortController();

		(async function fetchMyGroup ()
		{
			try
			{
				var { group } = await request('/my-account/get-my-group')
				.config({ controller })
				.post();
			}
			catch (update)
			{
				return;
			}

			setMyGroupStore({ group: group });
		})();

		return () => controller?.abort?.();
	},
	[mygroup_status]);

	useEffect(() =>
	{
		if (membership_status === 'fetched') return;

		const controller = new AbortController();

		(async function fetchMyGroup ()
		{
			try
			{
				var { membership } = await request('/my-account/get-membership')
				.config({ controller })
				.post();
			}
			catch (update)
			{
				return;
			}

			setMembershipStore({ membership: membership });
		})();

		return () => controller?.abort?.();
	},
	[membership_status]);

	return (
		<SuspendedLayout resolve={ page }>

			<PrimeReactProvider value={ prime_params }>

				<div className="back-page">
					<div className={ bemit('o-header', ['admin']) }>
						<AdminHeader />
						<AdminUserMenu />
					</div>

					<Outlet />
				</div>

			</PrimeReactProvider>

		</SuspendedLayout>
	);
}