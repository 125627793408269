import { defer } from 'react-router-dom'

import resetForm from '../Functions/resetForm.js'
import request   from '../Functions/ajax/request'

import FormsStore from '../../Stores/Forms'

import { someTime } from '../../../shared/utilities'

export default function ThanksLoader ({ params })
{
	return defer({ thanks: getThanks(true, params), params });
}

async function getThanks (retry=true, params)
{
	resetForm('Thanks');

	try
	{
		var { thanks } = await request('/thanks/get')
		.post({ id: params.id || '-1', with_by: true });
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		thanks = await getThanks(false, params);
	}

	FormsStore.update(function (store)
	{
		store['Thanks'] =
		{
			'id': thanks._id,
			'thanks[to_id]': thanks.to_id,
			'thanks[amount]': String(thanks.amount)
		};
	});

	return thanks;
}