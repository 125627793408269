import { defer } from 'react-router-dom'

import resetForm from '../Functions/resetForm.js'
import request   from '../Functions/ajax/request'

import FormsStore from '../../Stores/Forms'

import { someTime } from '../../../shared/utilities'
import { DateTime } from 'luxon'

export default function MtoMLoader ({ params })
{
	return defer({ mtom: getMtoM(true, params), params });
}

async function getMtoM (retry=true, params)
{
	resetForm('MtoM');

	try
	{
		var { mtom } = await request('/m-to-m/get')
		.post({ id: params.id || '-1' });
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		mtom = await getMtoM(false, params);
	}

	FormsStore.update(function (store)
	{
		store['MtoM'] =
		{
			'id': mtom._id,
			'mtom[to_id]': mtom.to_id,
			'mtom[date]': DateTime.fromISO(mtom.date).toISODate()
		};
	});

	return mtom;
}