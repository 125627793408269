import { defer } from 'react-router-dom'

import request from '../Functions/ajax/request'

import { someTime } from '../../../shared/utilities'

export default async function PatronageRegistrationLoader (params)
{
	return defer({ infos: getRegistrationInfos() });
}

async function getRegistrationInfos (retry=true)
{
	let pathname = document.location.pathname;

	if (pathname.endsWith('/') && pathname !== '/')
	{
		pathname = pathname.slice(0, -1);
	}

	const token = pathname.slice(pathname.lastIndexOf('/')+1);

	try
	{
		var infos = await request('/patronage/get-registration-infos')
		.post({ token: token });
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		if (update && update.error) throw update;

		await someTime(5000);

		infos = await getRegistrationInfos(false);
	}

	return { ...infos, token };
}