import { useEffect } from 'react'

import { Outlet, useLoaderData } from 'react-router-dom'

import { PrimeReactProvider } from 'primereact/api'

import request from '../Functions/ajax/request'

import RolesStore from '../../Stores/Roles'

import setRolesStore from '../../StoresFunctions/setRolesStore'

import SuspendedLayout from '../../SuspendedLayout'

const prime_params = { ripple: true, unstyled: true };

export default function FrontLayout ()
{
	const { page } = useLoaderData();

	const roles_status = RolesStore.useState(s => s.status);

	useEffect(() =>
	{
		if (roles_status !== 'pending') return;

		const controller = new AbortController();

		(async function fetchRoles ()
		{
			try
			{
				var { roles, user_id } = await request('/auth/get-roles')
				.config({ controller })
				.post();
			}
			catch (update)
			{
				return;
			}

			setRolesStore({ roles, user_id });
		})();

		return () => controller?.abort?.();
	},
	[roles_status]);

	return (
		<SuspendedLayout resolve={ page }>

			<PrimeReactProvider value={ prime_params }>

				<div className="front-page">
					<main id="main" role="main"
						className="page-content page-content--front">
						<Outlet />
					</main>
				</div>

			</PrimeReactProvider>

		</SuspendedLayout>
	);
}