const DeletedPage = () => 'Page supprimée.';

export const HomePage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/HomePage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const AdminHomePage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/accueil/AdminHomePage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const BillsListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/liste-des-factures/BillsListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const UsersListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/liste-des-utilisateurs/UsersListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewUserPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/liste-des-utilisateurs/nouvel-utilisateur/NewUserPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const UserPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/liste-des-utilisateurs/[user_id]/UserPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const MembersToValidatePage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/admin/membres-en-attente-de-validation/MembersToValidatePage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const MembershipsListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/liste-des-adhesions/MembershipsListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewMembershipPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/liste-des-adhesions/nouvelle-adhesion/NewMembershipPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const MembershipPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/liste-des-adhesions/[membership_id]/MembershipPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const GroupsListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/liste-des-groupes/GroupsListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewGroupPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/liste-des-groupes/nouveau-groupe/NewGroupPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const GroupPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/liste-des-groupes/[group_id]/GroupPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const InviteMemberPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/liste-des-groupes/[group_id]/[user_id]/InviteMemberPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const MyNovasListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/mes-novas/MyNovasListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const MyMembershipPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/mon-adhesion/MyMembershipPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const MyAccountPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/mon-compte/MyAccountPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const MyGroupPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/mon-groupe/MyGroupPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const RecosListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/recommandations/RecosListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewRecoPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/recommandations/faire-une-reco/NewRecoPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const RecoPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/recommandations/[reco_id]/RecoPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const ThanksListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/remerciements/ThanksListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewThanksPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/remerciements/nouveau/NewThanksPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const ThanksPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/remerciements/[thank_id]/ThanksPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const MeetingsListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/reunions/MeetingsListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const MeetingPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/reunions/[meeting_id]/MeetingPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const MtoMListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/tete-a-tete/MtoMListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewMtoMPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/tete-a-tete/faire-un-tete-a-tete/NewMtoMPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const UpdateMtoMPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/gestion/tete-a-tete/[mtom_id]/UpdateMtoMPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const LoginPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/login/LoginPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const RequestPasswordPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/mot-de-passe-oublie/RequestPasswordPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewPasswordPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/mot-de-passe-oublie/nouveau-mot-de-passe/[token]/NewPasswordPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const NewsListPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/news/NewsListPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

export const PatronageRegistrationPage = async function ()
{
	try
	{
		var { default: Component } = await import('./pages/parrainage/[token]/PatronageRegistrationPage');
	}
	catch (e)
	{
		Component = DeletedPage;
	}
	return { Component };
};

const ROUTES = { HomePage, AdminHomePage, BillsListPage, UsersListPage, NewUserPage, UserPage, MembersToValidatePage, MembershipsListPage, NewMembershipPage, MembershipPage, GroupsListPage, NewGroupPage, GroupPage, InviteMemberPage, MyNovasListPage, MyMembershipPage, MyAccountPage, MyGroupPage, RecosListPage, NewRecoPage, RecoPage, ThanksListPage, NewThanksPage, ThanksPage, MeetingsListPage, MeetingPage, MtoMListPage, NewMtoMPage, UpdateMtoMPage, LoginPage, RequestPasswordPage, NewPasswordPage, NewsListPage, PatronageRegistrationPage };
export default ROUTES;