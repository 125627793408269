import { DateTime } from 'luxon'

import getSessionObject from '../Components/Functions/getSessionObject'

export default function getMembershipStoreInit ()
{
	const store =
	{
		status: 'pending'
	};

	const status = window.localStorage.getItem('membership@status');
	const date = DateTime.fromISO(window.localStorage.getItem('membership@date'));

	let expired = date.isValid
	? date.plus({ hours: 24 }) < DateTime.now()
	: true;

	if (!expired)
	{
		expired = date.startOf('day') < DateTime.now().startOf('day');
	}

	if (expired) return store;

	store.membership = getSessionObject('membership') || { };

	if (status) store.status = status;

	store.date = date.toISO();

	return store;
}