import { DateTime } from 'luxon'

import MyGroupStore from '../Stores/MyGroupStore'

export default function setMyGroupStore ({ group })
{
	MyGroupStore.update(function (store)
	{
		store.group = group;

		store.date = DateTime.now().toISO();

		store.status = group ? 'fetched' : 'no-group';

		try
		{
			window.localStorage
			.setItem('group', JSON.stringify(store.group || { }));
		}
		catch (e)
		{
			console.error('Impossible d\'enregistrer le groupe de l\'utilisateur');
		}

		window.localStorage.setItem('mygroup@date', store.date);
		window.localStorage.setItem('mygroup@status', store.status);
	});
}