export const site_name = 'Novarezo';
export const logo = '/images/logo.svg';

export const colors =
{
	primary: '#152039'
};

export const js_version = '0.1.0a';

export const js_refresh_rate = { minutes: 30 }; // luxon Duration params

const config = { site_name, logo, colors, js_version, js_refresh_rate };

export default config;