import { useState, useEffect, useRef, useCallback } from 'react'

import { useNavigate, Link, useLocation } from 'react-router-dom'

import { PiUserCircleGear } from 'react-icons/pi'
import { IoTriangle } from 'react-icons/io5'

import Spinner from '../Spinners/Spinner'

import MembershipStore from '../../Stores/MembershipStore'
import RolesStore      from '../../Stores/Roles'

import setRolesStore from '../../StoresFunctions/setRolesStore'
import setPagesStore from '../../StoresFunctions/setPagesStore'

import nodeIsOrBelongsTo from '../Functions/nodeIsOrBelongsTo'
import request           from '../Functions/ajax/request'

export default function AdminUserMenu ({ ...props })
{
	const membership = MembershipStore.useState(store => store.membership);
	const roles = RolesStore.useState(store => store.roles);

	const location = useLocation();

	const [open, setOpen] = useState(false);
	const [disconnecting, setDisconecting] = useState(false);

	const ref = useRef();

	useEffect(function ()
	{
		if (open !== true) return;

		document.addEventListener('click', listener);

		return () => document.removeEventListener('click', listener);

		function listener (e)
		{
		// ignore le click s'il est dans le menu, on le ferme sinon
			if (nodeIsOrBelongsTo(e?.target, ref.current)) return;

			setOpen(false);
		}
	},
	[open]);

	const logout = useCallback(function (e)
	{
		const controller = new AbortController();

		setDisconecting(true);

		(async function logout ()
		{
			try
			{
				var { pages, version } = await request('/auth/logout')
				.config({ controller })
				.post();
			}
			catch (update)
			{
				if (controller?.signal?.aborted) return;
				return;
			}

			setPagesStore({ pages, version });
			setRolesStore({ });

			setDisconecting(false);

			window.location.href = '/login';
		})();

		return () => controller?.abort?.();
	},
	[]);

	useEffect(function ()
	{
		setOpen(false);
	},
	[location.pathname]);

	if (roles.length === 0) return null;

	return (
		<div className="o-user-menu" ref={ ref }>
			<button type="button" className="o-user-menu__toggle"
				onClick={ e => setOpen(state => !state) }>
				<PiUserCircleGear className="o-user-menu__toggle-icon" />
				<span className="u-readers-only">
					Menu utilisateur
				</span>
			</button>

			<ul className="o-user-menu__list" aria-hidden={ !open }>

				{
					location.pathname !== '/gestion/mon-compte' ?
					(
						<li className="o-user-menu__list-item">
							<Link to="/gestion/mon-compte" 
								className="o-user-menu__list-btn">
								<span className="o-user-menu__list-btn-txt">
									Mon compte
								</span>
							</Link>
						</li>
					)
					: undefined
				}

				{
					membership && location.pathname !== '/gestion/mon-adhesion' ?
					(
						<li className="o-user-menu__list-item">
							<Link to="/gestion/mon-adhesion" 
								className="o-user-menu__list-btn">
								<span className="o-user-menu__list-btn-txt">
									Mon adhésion
								</span>
							</Link>
						</li>
					)
					: undefined
				}

				<li className="o-user-menu__list-item">
					<button type="button" className="o-user-menu__list-btn"
						onClick={ logout }>
						<span className="o-user-menu__list-btn-txt">
							Me déconnecter
						</span>
						<Spinner active={ disconnecting }
							bemit={ ['bg-white', 'primary'] }
						/>
					</button>
				</li>
			</ul>

			<IoTriangle className="o-user-menu__rect" />
		</div>
	);
}