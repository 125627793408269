import { defer } from 'react-router-dom'

import request from '../Functions/ajax/request'

import { someTime } from '../../../shared/utilities'

export default function MyMembershipLoader (params)
{
	return defer({ memberships: getMyMembership() });
}

async function getMyMembership (retry=true)
{
	try
	{
		var memberships = await request('/memberships/get-mines')
		.config({ })
		.post();
	}
	catch (update)
	{
		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		memberships = await getMyMembership(false);
	}

	return memberships;
}