import { defer } from 'react-router-dom'

import request from '../Functions/ajax/request'

import getPageHttpCode from '../Functions/getPageHttpCode'
import matchPage       from '../Functions/matchPage'

import getPagesStoreInit from '../../StoresFunctions/getPagesStoreInit'
import setPagesStore     from '../../StoresFunctions/setPagesStore'

import { someTime } from '../../../shared/utilities'

// permet de différencier le tout premier chargement de la page des changements
// de page internes à React Router - on se base sur le code de la page
// pour savoir si la page existe ou non dans un premier temps
// afin de ne pas bloquer le rendu initial et améliorer les temps de chargement
let first_page_load = true;

export default async function LayoutLoader ({ params })
{
	return defer({ page: getPage() });
}

async function getPage (retry=true)
{
	const default_store = getPagesStoreInit();

	let { pages, version } = default_store;

	if (first_page_load)
	{
		first_page_load = false;

		if (getPageHttpCode() === 200)
		{
			fetchPages(); // pas d'await - non bloquant

			return null;
		}
	}

	return fetchPages();

	async function fetchPages ()
	{
		try
		{
			const store = default_store.status === 'fetched'
			? default_store
			: await request('/auth/get-pages')
			.config({ })
			.post();

			pages = store.pages;
			version = store.version;

			setPagesStore({ pages, version });
		}
		catch (update)
		{
			console.error(update);

			if (!retry) throw { code: 503 };

			await someTime(5000);

			return getPage(false);
		}

		try
		{
			var page = matchPage(pages, document.location.pathname);
		}
		catch (e)
		{
			console.error(e);
		}

		if (!page) throw { code: 401 };

		return page;
	}
}