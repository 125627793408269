import { useEffect } from 'react'

import { Outlet, useLoaderData } from 'react-router-dom'

import Image from '../Pure/Image'
import Logo  from '../Pure/Logo'

import request from '../Functions/ajax/request'

import RolesStore from '../../Stores/Roles'

import setRolesStore from '../../StoresFunctions/setRolesStore'

import SuspendedLayout from '../../SuspendedLayout'

import config from '../../shared_config'

const img_config =
{
	original: 1000,
	desktop: 1000,

	available: [500, 1000, 1500, 2000, 3000]
};

export default function AuthLayout ()
{
	const { page } = useLoaderData();

	const roles_date = RolesStore.useState(s => s.date);

	useEffect(() =>
	{
		const controller = new AbortController();

		(async function fetchRoles ()
		{
			if (roles_date) return;

			try
			{
				var { roles, user_id } = await request('/auth/get-roles')
				.config({ controller })
				.post();
			}
			catch (update)
			{
				return;
			}

			setRolesStore({ roles, user_id });
		})();

		return () => controller?.abort?.();
	},
	[roles_date]);

	return (
		<SuspendedLayout resolve={ page }>
			<div className="auth-page">

				<div className="auth-page__left">
					<h1 className="u-readers-only">
						{ config.site_name }
					</h1>

					<Logo className="auth-page__logo" width="243" height="184" />

					<Outlet />
				</div>

				<div className="auth-page__right">
					<Image alt="" className="auth-page__right-img"
						src="/images/auth_bg.webp"
						src_config={ img_config }
					/>
				</div>

			</div>
		</SuspendedLayout>
	);
}