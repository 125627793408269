import { defer } from 'react-router-dom'

import request from '../Functions/ajax/request'

import { someTime } from '../../../shared/utilities'

export default async function RecoLoader ({ params={ } })
{
	return defer({ reco: getReco(true, params) });
}

async function getReco (retry=true, params)
{
	try
	{
		var { reco } = await request('/recos/get-recos')
		.post({ id: params.reco, with_by: true });
	}
	catch (update)
	{
		if (['DENIED', 'FORBIDDEN'].includes(update.ERROR_CODE))
		{
			throw { code: 401, throw_up: true };
		}

		if (update.ERROR_CODE) throw update;

		if (!retry) throw update;

		await someTime(5000);

		reco = await getReco(false, params);
	}

	return reco;
}